import gridTheme from '../../../../utils/gridTheme'

const breakpoints = gridTheme.breakpoints

// TODO: Somehow, sometimes 1968 is returned instead of `0` to indicate the
//  smallest breakpoint. Find out the root cause on WordPress at some point.
const paddings = {
  // Keys are the breakpoint for each option
  normal: {
    0: 64,
    1968: 64,
    [breakpoints.md]: 96,
    [breakpoints.xl]: 128,
  },
  narrow: {
    0: 32,
    1968: 32,
    [breakpoints.md]: 48,
    [breakpoints.xl]: 64,
  },
  none: {
    0: 0,
    1968: 0,
    [breakpoints.md]: 0,
    [breakpoints.xl]: 0,
  },
}

export const getPaddingValue = (
  padding: keyof typeof paddings,
  breakpoint = 0 | breakpoints.md | breakpoints.xl
): number => {
  if (!paddings[padding]) return 0

  return paddings[padding][breakpoint as 0 | 768 | 1200]
}

/**
  Returns either a background image or a background gradient to use for the
  `background-image` property, based on the retrieved block information.
  Prority is:
    1. Background image
    2. Background gradient

  If neither are present, returns an empty string.

  @param {Object} props - The element's props.
  @param {boolean} props.useMoneyFarmPattern - Whether to use the MoneyFarm pattern.
  @param {string} props.backgroundGradient - The background gradient.

  @returns {String} - The background image or gradient to use.
*/
export const getDisplayedCSSBackgroundImage = ({
  backgroundGradient,
}: {
  backgroundGradient?: string
}) => {
  if (backgroundGradient) return `background-image: ${backgroundGradient};`

  return ''
}
