/** Generates CSS for a gradient from an array of stops and an angle.
 * @param {Object} gradientInfo - An object containing the gradient stops and angle.
 * @param {Array<{ placement: number; hexCode: string; }>} gradientInfo.stops - An array of gradient stops.
 * @param {number} gradientInfo.angle - The angle of the gradient.
 *
 * @returns {String} CSS for the gradient.
 *
 * @example
 * gradientInfoToCSS({ stops: [{ placement: 0, hexCode: "#000000" }, { placement: 100, hexCode: "#FFFFFF" }], angle: 0 });
 * // => "linear-gradient(0deg, #000000 0%, #FFFFFF 100%)"
 */
export const gradientInfoToCSS = ({ stops, angle }) =>
  Array.isArray(stops) &&
  !isNaN(angle) &&
  `linear-gradient(${angle}deg, ${stops
    .map(({ hexCode, placement }) =>
      hexCode.concat(' ', placement.toString(), '%')
    )
    .join(', ')})`
