import React, { CSSProperties, FC, ReactNode } from 'react'

import { acceptableFileTypesForWebp } from '../../../../utils/fileTypesForWebP'
import { gradientInfoToCSS } from '../../../../utils/gradientInfoToCSS'
import { ImageData } from '../../../../types/Image'
import gridTheme from '../../../../utils/gridTheme'
import {
  Image,
  OverlapWrapper,
  PictureWrapper,
  StripeBlock,
} from './Stripe.styles'

const classNameHeroPreviewImage = 'hero-preview-image'

type Props = {
  children: ReactNode
  customBackgroundColor?: string
  id?: string
  image?: string
  imageAlign?: 'left' | 'right'
  overlap?: number
  marginBottom?: string
  paddingBottom?: 'normal' | 'narrow' | 'none'
  paddingTop?: 'normal' | 'narrow' | 'none'
  hiddenAt?: string
  style?: CSSProperties
  backgroundGradient?: string
}

export const Stripe: FC<Props> = ({
  children,
  customBackgroundColor,
  id,
  image,
  imageAlign,
  overlap,
  marginBottom,
  paddingBottom = 'normal',
  paddingTop = 'normal',
  hiddenAt,
  style,
  backgroundGradient,
}) => {
  let parsedImage: ImageData | null = !!image ? JSON.parse(image) : null

  const parsedBackgroundGradient = !!backgroundGradient
    ? JSON.parse(backgroundGradient)
    : null

  const sources = (image: ImageData) => {
    const mediaQueryMd = `(min-width: ${gridTheme.breakpoints.md}px)`
    const mediaQueryXs = `(min-width: 0px)`
    const imageUrlFull = image.sizes?.full.url
    const imageUrlMediumLarge = image.sizes?.medium_large?.url
    const imageSubtype = `image/${image.subtype}`

    if (acceptableFileTypesForWebp.test(image.subtype)) {
      return (
        <>
          <source
            className={classNameHeroPreviewImage}
            media={mediaQueryMd}
            srcSet={`${imageUrlFull}.webp`}
            type="image/webp"
          />
          <source
            className={classNameHeroPreviewImage}
            media={mediaQueryMd}
            srcSet={imageUrlFull}
            type={imageSubtype}
          />
          <source
            className={classNameHeroPreviewImage}
            media={mediaQueryXs}
            srcSet={`${imageUrlMediumLarge}.webp`}
            type="image/webp"
          />
          <source
            className={classNameHeroPreviewImage}
            media={mediaQueryXs}
            srcSet={imageUrlMediumLarge}
            type={imageSubtype}
          />
        </>
      )
    }

    return (
      <>
        <source
          className={classNameHeroPreviewImage}
          media={mediaQueryMd}
          srcSet={imageUrlFull}
          type={imageSubtype}
        />
        <source
          className={classNameHeroPreviewImage}
          media={mediaQueryXs}
          srcSet={imageUrlMediumLarge}
          type={imageSubtype}
        />
      </>
    )
  }

  return (
    <StripeBlock
      hiddenAt={hiddenAt}
      id={id}
      backgroundColor={customBackgroundColor}
      backgroundGradient={
        !!parsedBackgroundGradient
          ? gradientInfoToCSS(parsedBackgroundGradient)
          : undefined
      }
      marginBottom={marginBottom}
      style={style}
    >
      <OverlapWrapper
        {...{ overlap, paddingBottom, paddingTop, hasImage: !!parsedImage }}
      >
        {children}
      </OverlapWrapper>

      {!!parsedImage && (
        <PictureWrapper image={parsedImage} align={imageAlign}>
          {/* Background picture. */}
          <picture>
            {sources(parsedImage)}

            <Image
              className={classNameHeroPreviewImage}
              src={parsedImage.sizes?.full?.url}
              alt={parsedImage.alt}
              loading="lazy"
            />
          </picture>
        </PictureWrapper>
      )}
    </StripeBlock>
  )
}
